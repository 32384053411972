@import "components/_variables";
@import "components/_mixins";

#promo-bar {
  padding:1rem;
  text-align: center;
  position: relative;
  .close {
    position: absolute;
    right:15px;
    padding:.9rem;
    top:0;
    cursor:pointer;
  }
  .slick-slide{
    font-size: 1.2rem;
    display:flex;
    align-items: center;
    justify-content: center;
  }
}
